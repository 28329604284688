import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MaterialTable from "@material-table/core";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CropFreeIcon from "@mui/icons-material/CropFree";
import GroupIcon from "@mui/icons-material/Group";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ReactECharts from "echarts-for-react";
import BlockchainTable from "./components/BlockchainTable";
import { useMonitorService } from "./useMonitorService";

// Dummy data
// const stats = {
//   qrCodesGenerated: 0,
//   qrCodesScanned: 0,
//   rfidActivated: 0,
//   rfidScanned: 0,
//   totalClients: 6,
//   totalProducts: 0,
// };
const productsPerClientOption = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    orient: "vertical",
    left: "right",
  },
  series: [
    {
      name: "SKU per Client",
      type: "pie",
      // radius: "50%", // Adjust as needed
      label: {
        show: true,
        // formatter: "{b}: {c} SKU", // {b} = name, {c} = value, {d} = percentage
        formatter: "{d}%", // {b} = name, {c} = value, {d} =
      },
      data: [
        { value: 0, name: "Ladang Sari International" },
        { value: 0, name: "Antares Ventures" },
        { value: 0, name: "Runningmen  Kitchen" },
        { value: 0, name: "Joeyees Trading" },
        { value: 0, name: "PPES Ternak " },
        { value: 0, name: "Gims Food" },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

const qrCodesPerClientOption = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    orient: "vertical",
    left: "right",
  },
  series: [
    {
      name: "QR Codes Activated per Client",
      type: "pie",
      label: {
        show: true,
        // formatter: "{b}: {c} QR Codes", // {b} = name, {c} = value, {d} = percentage
        formatter: "{d}%", // {b} = name, {c} = value, {d} = percentage
      },
      data: [
        { value: 0, name: "Ladang Sari International" },
        { value: 0, name: "Antares Ventures" },
        { value: 0, name: "Runningmen  Kitchen" },
        { value: 0, name: "Joeyees Trading" },
        { value: 0, name: "PPES Ternak " },
        { value: 0, name: "Gims Food" },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

const dummyData = [
  // { id: 1, code: "RFID001", scanned: 23, dateActivated: "2024-01-01", createdBy: "PPES Ternak " },
  // { id: 2, code: "RFID002", scanned: 17, dateActivated: "2024-02-15", createdBy: "Gims Food" },
  // { id: 3, code: "RFID003", scanned: 12, dateActivated: "2024-03-10", createdBy: "Ladang Sari International" },
  // { id: 4, code: "RFID004", scanned: 8, dateActivated: "2024-04-22", createdBy: "Runningmen  Kitchen" },
  // { id: 5, code: "RFID005", scanned: 30, dateActivated: "2024-05-30", createdBy: "Antares Ventures" },
  // { id: 6, code: "RFID006", scanned: 19, dateActivated: "2024-06-18", createdBy: "PPES Ternak " },
];

const options = {
  columnsButton: true,
  paging: false,
};

const columns = [
  { field: "id", title: "ID", width: 90, hidden: true },
  { field: "code", title: "Code", width: 130 },
  { field: "scanned", title: "Scanned Count", type: "number", width: 150 },
  { field: "dateActivated", title: "Date Activated", width: 160 },
  { field: "createdBy", title: "Belongs To", width: 150 },
];

const Monitor = (props) => {
  const { findStats, findTotalSKUByClient, findTotalActiveQRByClient, findLatestBlock } = useMonitorService();

  const [stats, setStats] = useState({
    totalQRActivated: 0,
    totalQRScanned: 0,
    totalRFIDActivated: 0,
    totalRFIDScanned: 0,
    totalUser: 6,
    totalSKU: 0,
  });
  const [skuByClient, setSkuByClient] = useState([]);
  const [qrActivatedByClient, setQrActivatedByClient] = useState([]);
  const [blockData, setBlockData] = useState([]);

  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (refresh) {
      Promise.all([findStats(), findTotalSKUByClient(), findTotalActiveQRByClient(), findLatestBlock()]).then(
        ([res1, res2, res3, res4]) => {
          if (res1 && res1.status === "success") {
            setStats(res1.data);
          }
          if (res2 && res2.status === "success") {
            setSkuByClient(res2.data);
          }
          if (res3 && res3.status === "success") {
            setQrActivatedByClient(res3.data);
          }
          if (res4 && res4.status === "success") {
            setBlockData(res4.data);
          }
          setRefresh(false);
        }
      );
    }
  }, [refresh]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL QR ACTIVATED
            </Typography>
            <QrCodeIcon htmlColor="#000000" />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h5" fontWeight={700} color="textSecondary" mt={2}>
              {stats.totalQRActivated}
            </Typography>
            <ArrowDropUpIcon color="success" sx={{ mt: 2 }} />
            <Typography color="success" fontSize={12} mt={2}>
              0% in the last 7 days
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL QR SCANNED
            </Typography>
            <QrCodeScannerIcon htmlColor="green" />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h5" fontWeight={700} color="textSecondary" mt={2}>
              {stats.totalQRScanned}
            </Typography>
            <ArrowDropUpIcon color="success" sx={{ mt: 2 }} />
            <Typography color="success" fontSize={12} mt={2}>
              0 in the last 7 days
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL RFID ACTIVATED
            </Typography>
            <QrCode2Icon htmlColor="#000000" />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h5" fontWeight={700} color="textSecondary" mt={2}>
              {stats.totalRFIDActivated}
            </Typography>
            <ArrowDropUpIcon color="success" sx={{ mt: 2 }} />
            <Typography color="success" fontSize={12} mt={2}>
              0% in the last 7 days
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL RFID SCANNED
            </Typography>
            <CropFreeIcon htmlColor="#f0cc19" />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h5" fontWeight={700} color="textSecondary" mt={2}>
              {stats.totalRFIDScanned}
            </Typography>
            <ArrowDropUpIcon color="success" sx={{ mt: 2 }} />
            <Typography color="success" fontSize={12} mt={2}>
              0 in the last 7 days
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL USERS
            </Typography>
            <GroupIcon htmlColor="maroon" />
          </Box>

          <Typography variant="h5" fontWeight={700} color="textSecondary" mt={2}>
            {stats.totalUser}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle2" color="textSecondary">
              TOTAL SKU
            </Typography>
            <Inventory2Icon htmlColor="#0077EE" />
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h5" fontWeight={700} mt={2} color="textSecondary">
              {stats.totalSKU}
            </Typography>
            {/* <ArrowDropUpIcon color="success" sx={{ mt: 2 }} />
            <Typography color="success" fontSize={12} mt={2}>
              0 in the last 7 days
            </Typography> */}
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle1">SKU per User</Typography>
          </Box>
          <ReactECharts
            option={{
              ...productsPerClientOption,
              series: [
                {
                  name: "SKU per Client",
                  type: "pie",
                  // radius: "50%", // Adjust as needed
                  label: {
                    show: true,
                    // formatter: "{b}: {c} SKU", // {b} = name, {c} = value, {d} = percentage
                    formatter: "{d}%", // {b} = name, {c} = value, {d} =
                  },
                  data: skuByClient.map((item) => ({
                    value: item.value,
                    name: item.name.replace("SDN BHD", "").replace("SDN. BHD.", ""),
                    // remove "SDN BHD" from the name,
                  })),
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ padding: 2 }}>
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
            <Typography variant="subtitle1">QR Activated per User</Typography>
          </Box>
          <ReactECharts
            option={{
              ...qrCodesPerClientOption,
              series: [
                {
                  name: "QR Codes Activated per Client",
                  type: "pie",
                  label: {
                    show: true,
                    // formatter: "{b}: {c} QR Codes", // {b} = name, {c} = value, {d} = percentage
                    formatter: "{d}%", // {b} = name, {c} = value, {d} = percentage
                  },
                  data: qrActivatedByClient.map((item) => ({
                    value: item.value,
                    name: item.name.replace("SDN BHD", "").replace("SDN. BHD.", ""),
                    // remove "SDN BHD" from the name,
                  })),
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ padding: 2 }}>
          <MaterialTable
            title={"Recent RFID Activity"}
            data={dummyData}
            columns={columns}
            options={options}
            components={{
              Container: (props) => <Paper elevation={0} {...props} />,
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <BlockchainTable data={blockData} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Monitor;
