import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, fp } from "../../App";

const useMonitorService = () => {
  const { authState } = useContext(AuthContext);

  const findStats = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/monitor/findStats", config);
  }, [authState]);

  const findTotalSKUByClient = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/monitor/findTotalSKUByClient", config);
  }, [authState]);

  const findTotalActiveQRByClient = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/monitor/findTotalActiveQRByClient", config);
  }, [authState]);

  const findLatestBlock = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/monitor/findLatestBlock", config);
  }, [authState]);

  return {
    findStats,
    findTotalSKUByClient,
    findTotalActiveQRByClient,
    findLatestBlock,
  };
};

export { useMonitorService };
