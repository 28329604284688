import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MaterialTable from "@material-table/core";
import { format } from "date-fns";

const dummyData = [
  // {
  //   block: 18,
  //   hash: "9e107d9d",
  //   previousHash: "d41d8cd9",
  //   instant: "2024-08-29T11:08:53+08:00",
  //   signature: "3046022100a3b",
  // },
  // {
  //   block: 17,
  //   hash: "d41d8cd9",
  //   previousHash: "b1946ac9",
  //   instant: "2024-08-29T09:11:59+08:00",
  //   signature: "3045022100c2a",
  // },
  // {
  //   block: 16,
  //   hash: "b1946ac9",
  //   previousHash: "3a1d5f24",
  //   instant: "2024-08-29T07:14:09+08:00",
  //   signature: "3046022100e5f",
  // },
  // {
  //   block: 15,
  //   hash: "3a1d5f24",
  //   previousHash: "1679091c",
  //   instant: "2024-08-29T05:16:15+08:00",
  //   signature: "3045022100f8c",
  // },
  // {
  //   block: 14,
  //   hash: "1679091c",
  //   previousHash: "c4ca4238",
  //   instant: "2024-08-29T03:18:17+08:00",
  //   signature: "3046022100f8c",
  // },
];

const columns = [
  { title: "Block", field: "_block/number", width: 50 },
  { title: "Hash", field: "_block/hash", render: (rowData) => rowData["_block/hash"].substring(0, 16) },
  {
    title: "Previous Hash",
    field: "_block/prevHash",
    render: (rowData) => rowData["_block/prevHash"].substring(0, 16),
  },
  {
    title: "Timestamp",
    field: "_block/instant",
    render: (rowData) => {
      return format(new Date(rowData["_block/instant"]), "yyyy-MM-dd HH:mm:ss");
    },
  },
  { title: "Signature", field: "_block/sigs", render: (rowData) => rowData["_block/sigs"][0].substring(0, 16) },
];

const BlockchainTable = (props) => {
  return (
    <MaterialTable
      title={"Latest Block Transactions"}
      data={props.data}
      columns={columns}
      //   options={options}
      components={{
        Container: (props) => <Paper elevation={0} {...props} />,
      }}
    />
  );
};

export default BlockchainTable;
